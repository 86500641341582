@import "../../scss/var";
.authLayoutWrap {
  @include min(768) {
    height: 100vh;
  }
}
.authLayoutBg {
  @include min(768) {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-color: #c2ebff;
    display: flex;
    align-items: flex-end;
    padding: 0 calc(5vw + 2rem);
    position: relative;
    & .authLayoutLogo {
      transform: translateY(-60%);
      max-width: 15vw;
      position: absolute;
      top: 60px;
      left: 30px;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @include max(767) {
    display: none;
  }
}

.authLayoutFormWrap {
  @include min(768) {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
