$primary: #1caec9;
$bgPrimary100: #e7e9f5;
$secondary: #36aec9;
$secondary100: #e1f8fb;
$white: #fff;
$secondary200: #8cdbe8;
$secondary400: #41c3d8;
$secondary600: #1caec9;
$btnHover: #1b91a7;
$headingColor: #222;
$textColor: #414858;
$textPrimary: #1b1d22;
$textSecondary: #6c7893;
$iconColor: #a5adc0;
$hoverItem: #f0fbfd;
$borderGrey: #eaecf0;
$greenColor: #21b089;
$orangeColor: #fe5500;
.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #1caec9 !important;
  --list-group-active-bg: #1caec9 !important;
  --list-group-active-border-color: #1caec9 !important;
  --bs-list-group-active-border-color: #1caec9 !important;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

@mixin min($value) {
  @media screen and (min-width: ($value + "px")) {
    @content;
  }
}
@mixin max($value) {
  @media screen and (max-width: ($value + "px")) {
    @content;
  }
}
